import request from '@/utils/request'
import Vuetify from '@/plugins/vuetify'
var langId = Vuetify.preset.lang.current

export function listAllAdmin (param) {
  return request({
    url: langId + '/admin',
    method: 'get',
    params: param
  })
}

export function detailAdmin (id) {
  return request({
    url: langId + '/admin/' + id,
    method: 'get'
  })
}

export function deleteAdmin (id) {
  return request({
    url: langId + '/admin/' + id,
    method: 'delete'
  })
}

export function createAdmin (data) {
  return request({
    url: langId + '/admin',
    method: 'post',
    data
  })
}

export function updateAdmin (data) {
  return request({
    url: langId + '/admin/update/' + data.id,
    method: 'post',
    data
  })
}

export function currentActivity (param) {
  return request({
    url: langId + '/activity/current',
    method: 'get',
    params: param
  })
}

export function listActivity (param) {
  return request({
    url: langId + '/activity',
    method: 'get',
    params: param
  })
}

export function detailActivity (data) {
  return request({
    url: langId + '/activity/' + data.id,
    method: 'get'
  })
}

export function deleteActivity (data) {
  return request({
    url: langId + '/activity/' + data.id,
    method: 'delete'
  })
}

export function countAdmin (data) {
  return request({
    url: langId + '/admin/count',
    method: 'get',
    params: data
  })
}

export function adminActivity (data) {
  return request({
    url: langId + '/admin/activity/' + data.id,
    method: 'get',
    params: data.param
  })
}
