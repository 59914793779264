<template>
    <div>
        <v-card flat>
          <div>
            <v-container class="white lighten-5 fill-height" style="width: 100%;">
                <div style="width: 100%;">
                    <v-card class="pa-2" width="100%">
                        <v-card-title>
                        <span style="color: #6A6A6A">{{ total_activity }} {{ lang.t('$vuetify.activities') }}</span>
                        <v-spacer></v-spacer>
                        <v-btn rounded outlined class="text-capitalize" style="margin-right: 8px"><v-icon left>$vuetify.icons.download</v-icon>{{ lang.t('$vuetify.download') }}</v-btn>
                        <v-btn rounded outlined class="text-capitalize" style="margin-right: 8px"><v-icon left>$vuetify.icons.print</v-icon>{{ lang.t('$vuetify.print') }}</v-btn>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                            style="width: 10px;"
                        ></v-text-field>
                        </v-card-title>
                        <v-skeleton-loader
                            :loading="listLoading"
                            :transition="transition"
                            type="table"
                          >
                        <v-data-table
                        :headers="headers"
                        :items="data"
                        :search="search"
                        :items-per-page="15"
                        hide-default-footer
                        class="mytable"
                        style="border-bottom: none"
                        @click:row="rowClick"
                        >
                        <template v-slot:header.name="{ header }">
                          <span style="color: #00B082">{{ lang.t('$vuetify.' + header.text) }}</span>
                        </template>
                        <template v-slot:header.role="{ header }">
                          <span style="color: #00B082">{{ lang.t('$vuetify.' + header.text) }}</span>
                        </template>
                        <template v-slot:header.activity="{ header }">
                          <span style="color: #00B082">{{ lang.t('$vuetify.' + header.text) }}</span>
                        </template>
                        <template v-slot:header.date="{ header }">
                          <span style="color: #00B082">{{ lang.t('$vuetify.' + header.text) }}</span>
                        </template>
                        <template v-slot:header.action="{ header }">
                          <span style="color: #00B082">{{ lang.t('$vuetify.' + header.text) }}</span>
                        </template>
                        <template v-slot:item.name="{ item }">
                        <v-row class="align-center" no-gutters>
                            <v-col :md="3" class="d-flex-align-center">
                                <v-avatar size="36" tile>
                                    <v-img :src="item.photo"></v-img>
                                </v-avatar>
                            </v-col>
                            <v-col >
                                <div class="subtitle-2 font-weight-bold">{{ item.name }} vvv</div>
                            </v-col>
                        </v-row>
                        </template>
                        <template v-slot:item.role="{ item }">
                          {{ item.role_name }}
                        </template>
                        <template v-slot:item.action="{ item }">
                        <v-btn icon>
                          <v-icon color="#00D79E" class="mr-2" @click="editItem(item)">mdi-file-edit-outline</v-icon>
                        </v-btn>
                        <button-confirm :name="item.name" :table="true" :message="'$vuetify.del_activity'" @confirm="delDataActivity(item)"></button-confirm>
                        </template>
                        </v-data-table>
                        </v-skeleton-loader>
                    </v-card>
                </div>
                <div class="align-center" style="width: 100%">
                    <v-spacer></v-spacer><br>
                    <v-pagination
                    v-model="page"
                    color="#00B082"
                    :length="total_page"
                    :total-visible="7"
                    circle
                    @input="changePage"
                    ></v-pagination>
                </div>
            </v-container>
            </div>
            <v-dialog
              v-model="dialog"
              max-width="30%">
              <v-card class="pb-5">
                <v-toolbar color="elevation-0">
                  <v-toolbar-title >
                    <span class="ml-4 font-weight-bold">{{ lang.t('$vuetify.activity_detail') }}</span>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn icon @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <div class="mx-6 mt-6">
                  <div class="body-1" style="color: #BEC2C4">{{ lang.t('$vuetify.activity') }}</div>
                  <div class="body-1">
                    <span style="color: #6A6A6A">Andi created deals for </span>
                    <span style="color: #00D79E">Haircut - Short </span>
                    <span style="color: #6A6A6A"> in </span>
                    <span style="color: #00D79E">Djemari Salon and Spa</span>
                  </div>
                  <div class="mt-6 body-1" style="color: #BEC2C4">Time</div>
                  <div class="body-1" style="color: #6A6A6A">Friday, 22 August 2020, 13:30</div>
                  <div class="mt-6 body-1" style="color: #BEC2C4">Created by</div>
                  <div>
                    <v-avatar size="28" class="mr-3">
                        <v-img src="@/assets/Screenshot(4).png"></v-img>
                    </v-avatar>
                    <span class="body-1 font-weight-bold">Andi Suseno</span>
                    <span class="body-1" style="color: #BEC2C4"> at </span>
                    <span class="body-1 font-weight-bold">15 Feb 2020, 13:30</span>
                </div>
                <div class="text-center mt-9" style="width: 100%">
                  <v-btn depressed rounded width="100%" color="#FDF1EF" style="color: #E74C3C; border: 1px solid #E74C3C">Delete Activity</v-btn>
                </div>
                </div>
              </v-card>
            </v-dialog>
            <v-snackbar v-model="notif.notifyUpdate" :timeout="notif.timeoutNotify" multi-line top right :color="notif.colorNotify" >
              {{ notif.textNotify }}
              <v-btn color="white" text @click="notif.notifyUpdate = false">
                {{ lang.t('$vuetify.close') }}
              </v-btn>
            </v-snackbar>
        </v-card>
    </div>
</template>

<script>
import { listActivity, detailActivity, deleteActivity } from '@/api/admin'

export default {
  name: 'ListActivity',

  data () {
    return {
      headers: [
        {
          text: 'admin_name',
          align: 'left',
          value: 'name'
        },
        {
          text: 'level',
          align: 'left',
          value: 'role'
        },
        {
          text: 'activity',
          align: 'left',
          value: 'activity'
        },
        {
          text: 'date',
          align: 'center',
          value: 'fdate'
        },
        {
          text: 'action',
          align: 'center',
          value: 'action'
        }
      ],
      search: '',
      dialog: false,
      step: 3,
      data: [],
      dataTemp: [],
      total_activity: 0,
      listLoading: true,
      transition: 'scale-transition',
      page: 0,
      total_page: 0,
      notif: {
        notifyUpdate: false,
        textNotify: '',
        timeoutNotify: 2000,
        colorNotify: ''
      }
    }
  },

  computed: {
    lang () {
      return this.$vuetify.lang
    }
  },

  mounted () {
    this.getAllActivity(1)
  },

  methods: {
    getAllActivity (page) {
      this.listLoading = true
      var params = {
        page: page,
        per_page: 10,
        sort_type: 'desc',
        sort_column: 'id'
      }
      listActivity(params).then((response) => {
        this.data = response.data.data
        this.total_page = response.data.meta.last_page
        this.page = response.data.meta.current_page
        this.total_activity = response.data.meta.total
        this.listLoading = false
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getDetailActivity (row) {
      // this.listLoading = true
      detailActivity(row).then((response) => {
        this.dataTemp = response.data.data
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    delDataActivity (data) {
      // this.listLoading = true
      deleteActivity(data).then((response) => {
        this.notif.textNotify = response.data.data.message
        this.notif.notifyUpdate = true
        this.notif.colorNotify = 'success'
        this.getAllActivity(1)
      }).catch((error) => {
        console.log('rsp', error)
        this.notif.textNotify = error
        this.notif.notifyUpdate = true
        this.notif.colorNotify = 'error'
      })
      // this.listLoading = false
    },

    rowClick (row) {
      this.getDetailActivity(row)
      this.dialog = true
    },

    getNext () {
      this.getAllActivity(this.page)
    },

    changePage (item) {
      this.getAllActivity(item)
    }
  }
}
</script>
