import request from '@/utils/request'
import Vuetify from '@/plugins/vuetify'
var langId = Vuetify.preset.lang.current

export function listCountry (param) {
  return request({
    url: langId + '/country',
    method: 'get',
    params: param
  })
}

export function listCity (param) {
  return request({
    url: langId + '/city',
    method: 'get',
    params: param
  })
}

export function listAdminRole (param) {
  return request({
    url: langId + '/role',
    method: 'get',
    params: param
  })
}

export function currencyAll () {
  return request({
    url: langId + '/currencyall',
    method: 'get'
  })
}

export function businesstype () {
  return request({
    url: langId + '/businesstype',
    method: 'get'
  })
}

export function logOut () {
  return request({
    url: langId + '/oauth/logout',
    method: 'get'
  })
}
