<template>
  <div>
    <v-card flat>
      <v-container class="white lighten-5 fill-height">
        <div class="d-flex" style="width: 100%;">
            <div v-for="(column, idy) in columnsAdmin" :key="idy" class="ma-3 d-flex flex-column flex-grow-1" >
            <div v-for="item in column" :key="item.id" style="padding: 5px; margin: 5px;">
                <div style="margin-top: 5px; width: 100%;">
                    <v-row class="mb-4 align-center" no-gutters>
                        <v-col :md="4" class="text-center d-flex-align-center">
                            <v-avatar :color="item.color">
                                <span class="headline" :style="item.text_color">{{ item.id }}</span>
                            </v-avatar>
                        </v-col>
                        <v-col >
                            <div class="subtitle-2 font-weight-bold">{{ item.name }}</div>
                            <div class="caption mb-2" style="color: #6A6A6A">{{ item.total }}</div>
                        </v-col>
                    </v-row>
                </div>
            </div>
            </div>
        </div>
        <v-card class="pa-2" width="100%">
            <v-card-title>
              <span class="mr-1" style="color: #6A6A6A">
                <v-select
                  :items="allTypes"
                  :clearable="true"
                  label="All Level"
                  style="width: 121px;"
                  @change="handleFilterType"
                />
              </span>
              <v-spacer></v-spacer>
              <v-btn
                rounded
                class="text-capitalize"
                style="margin-right: 8px; color: #FFFFFF"
                color="#00D79E"
                @click="addNewAdmin">
                {{ '+ Add Admin' }}
              </v-btn>
              <v-btn rounded outlined class="text-capitalize" style="margin-right: 8px"><v-icon left>$vuetify.icons.download</v-icon>{{ lang.t('$vuetify.download') }}</v-btn>
              <v-btn rounded outlined class="text-capitalize" style="margin-right: 8px"><v-icon left>$vuetify.icons.print</v-icon>{{ lang.t('$vuetify.print') }}</v-btn>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                style="width: 10px;"
              ></v-text-field>
            </v-card-title>
            <v-skeleton-loader
              :loading="listLoading"
              :transition="transition"
              type="table"
            >
            <v-data-table
              :headers="headers"
              :items="listAdmin"
              :search="search"
              @click:row="rowClick"
              hide-default-footer
              class="mytable"
              style="border-bottom: none"
            >
            <template v-slot:header.name="{ header }">
              <span style="color: #00B082">{{ lang.t('$vuetify.' + header.text) }}</span>
            </template>
            <template v-slot:header.role="{ header }">
              <span style="color: #00B082">{{ lang.t('$vuetify.' + header.text) }}</span>
            </template>
            <template v-slot:header.last_activity="{ header }">
              <span style="color: #00B082">{{ lang.t('$vuetify.' + header.text) }}</span>
            </template>
            <template v-slot:header.action="{ header }">
              <span style="color: #00B082">{{ lang.t('$vuetify.' + header.text) }}</span>
            </template>
            <template v-slot:item.name="{ item }">
              <v-row class="align-center" no-gutters >
                <v-col :md="2" class="d-flex-align-center">
                    <v-avatar size="36" tile>
                        <v-img :src="item.photo"></v-img>
                    </v-avatar>
                </v-col>
                <v-col >
                    <div class="subtitle-2 font-weight-bold">{{ item.name }}</div>
                </v-col>
            </v-row>
            </template>
            <template v-slot:item.role="{ item }">
              <div style="width: 100%" >
                {{ item.role_name }}
              </div>
            </template>
            <template v-slot:item.last_activity="{ item }">
              <div v-if="item.last_activity.length > 0" style="width: 100%" >
                {{ item.last_activity }}
              </div>
              <div v-else style="width: 100%" >
                No Activity
              </div>
            </template>
            <template v-slot:item.action="{ item }">
                <v-btn icon>
                <v-icon
                color="#00D79E"
                class="mr-2"
                @click="editItem(item)">mdi-file-edit-outline</v-icon>
              </v-btn>
                <button-confirm :name="item.name" :table="true" :message="'$vuetify.del_account'" @confirm="delDataAdmin(item.id)"></button-confirm>
            </template>
            </v-data-table>
          </v-skeleton-loader>

            <v-spacer></v-spacer><br>
            <v-pagination
              v-model="page"
              color="#00B082"
              :length="total_page"
              :total-visible="7"
              circle
              @input="changePage"
              ></v-pagination>
        </v-card>
      </v-container>

      <v-dialog
        v-model="dialog"
        max-width="664">
        <v-card class="pb-5">
          <v-toolbar color="elevation-0">
            <v-toolbar-title >
              <span class="ml-4 font-weight-bold">{{ lang.t('$vuetify.add') }} {{ lang.t('$vuetify.new_admin') }}</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <div class="mx-6 mt-6">
            <div class="ml-2">
              <v-row>
              <v-col :md="6" >
                  <div class="justify-space-between">
                      <div class="d-flex flex-no-wrap justify-space-between mb-3">
                          <span>{{ lang.t('$vuetify.name') }}</span>
                      </div>
                      <v-text-field v-model="data.name" outlined dense></v-text-field>
                      <!-- <div class="d-flex flex-no-wrap justify-space-between mb-3">
                          <span>{{ lang.t('$vuetify.username') }}</span>
                      </div>
                      <v-text-field v-model="data.username" outlined dense></v-text-field> -->
                      <div class="d-flex flex-no-wrap justify-space-between mb-3">
                          <span>{{ lang.t('$vuetify.email') }}</span>
                      </div>
                      <v-text-field v-model="data.email" outlined dense :rules="[rules.required, rules.email]"></v-text-field>
                      <div class="d-flex flex-no-wrap justify-space-between mb-3">
                          <span class="mt-4">{{ lang.t('$vuetify.password') }}</span>
                      </div>
                      <v-text-field v-model="data.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" outlined dense></v-text-field>
                      <div class="d-flex flex-no-wrap justify-space-between mb-3">
                          <span>{{ lang.t('$vuetify.phone_number') }}</span>
                      </div>
                      <!-- <v-text-field v-model="data.mobile" outlined dense></v-text-field> -->
                      <phone-number-input
                        class="country-selector"
                        v-model="data.mobile_format"
                        default-country-code="ID"
                        :translations="{
                          countrySelectorLabel: '',
                          countrySelectorError: '',
                          phoneNumberLabel: '',
                          example: ''
                        }"
                        valid-color="#E0E0E0"
                        :preferred-countries="['ID', 'SG']"
                        :no-example="true"
                        @update="getPhone"
                      />
                  </div>
              </v-col>
              <v-col :md="6" >
                  <div class="justify-space-between">
                      <div class="d-flex flex-no-wrap justify-space-between" >
                          <span class="mt-1 mb-2">{{ lang.t('$vuetify.admin') }} {{ lang.t('$vuetify.level') }}</span>
                      </div>
                      <v-autocomplete v-model="data.role_id" :items="listAdminRole" item-text="name" item-value="id" outlined dense ></v-autocomplete>
                      <div class="d-flex flex-no-wrap justify-space-between mb-3">
                          <span>{{ lang.t('$vuetify.country') }}</span>
                      </div>
                      <v-autocomplete v-model="data.country_id" :items="listCountry" item-text="name" item-value="id" outlined dense ></v-autocomplete>
                      <div class="d-flex flex-no-wrap justify-space-between mb-3">
                          <span>{{ lang.t('$vuetify.city') }}</span>
                      </div>
                      <v-autocomplete v-model="data.city_id" :items="listCity" item-text="name" item-value="id" outlined dense ></v-autocomplete>
                      <div class="d-flex flex-no-wrap justify-space-between mt-5">
                          <span class="mt-5 subtitle-1" style="color: #BEC2C4">{{ lang.t('$vuetify.upload') }} {{ lang.t('$vuetify.photo') }}</span>
                          <span class="mt-6 caption" style="color: #BEC2C4">png or jpg, max 300 kb</span>
                      </div>
                      <v-file-input :rules="rulesUpload" accept="image/png, image/jpeg, image/bmp" placeholder="Pick an avatar" prepend-icon="mdi-camera" @update:error="handleUpload" style="border-color: red"></v-file-input>
                  </div>
              </v-col>
              </v-row>
                <div class="text-center">
                  <v-btn width="552px" class="text-capitalize" rounded color="#00D79E" style="color: #FFFFFF" @click="saveAdmin">{{ lang.t('$vuetify.save') }}</v-btn>
                </div>
              </div>
          </div>
        </v-card>
      </v-dialog>
    </v-card>

    <v-snackbar
      v-model="notifyUpdate"
      :timeout="timeoutNotify"
      multi-line
      top
      right
      :color="colorNotify" >
      <div>
        <div class="subtitle-2 font-weight-bold">Status code {{ titleNotify }}</div>
        <div>{{ textNotify }}</div>
      </div>
      <v-btn color="white" text @click="notifyUpdate = false">
        {{ lang.t('$vuetify.close') }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { listAllAdmin, createAdmin, currentActivity, countAdmin, deleteAdmin } from '@/api/admin'
import { listCountry, listCity, listAdminRole } from '@/api/global'

export default {
  name: 'AdminOverview',

  data () {
    return {
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
      },
      rulesUpload: [
        value => !value || value.size < 300000 || 'Image size should be less than 300 kb!'
      ],
      show1: false,
      adminList: [
        {
          id: 1,
          name: 'Administrator',
          color: '#FFEBEE',
          text_color: 'color: #C44569;',
          total_user: '2 User'
        },
        {
          name: 'Supervisor',
          id: 2,
          color: '#FFFDE7',
          text_color: 'color: #FFD32A;',
          total_user: '2 User'
        },
        {
          name: 'Marketing',
          id: 3,
          color: '#E1F5FE',
          text_color: 'color: #3DC1D3;',
          total_user: '2 User'
        },
        {
          name: 'Finance',
          id: 4,
          color: '#F3E5F5',
          text_color: 'color: #786FA6',
          total_user: '2 User'
        },
        {
          name: 'Digital Marketing',
          id: 5,
          color: '#E8F5E9',
          text_color: 'color: #05C46B',
          total_user: '2 User'
        },
        {
          name: 'Technical Support',
          id: 6,
          color: '#E0F2F1',
          text_color: 'color: #55E6C1',
          total_user: '2 User'
        }
      ],
      headers: [
        {
          text: 'admin_name',
          align: 'left',
          value: 'name'
        },
        {
          text: 'level',
          align: 'left',
          value: 'role'
        },
        {
          text: 'last_activity',
          align: 'center',
          value: 'last_activity'
        },
        {
          text: 'action',
          align: 'right',
          value: 'action'
        }
      ],
      search: '',
      dialog: false,
      step: 3,
      listAdmin: [],
      listCountry: [],
      listCity: [],
      listAdminRole: [],
      data: {
        country: ''
      },
      dataActivity: [],
      adminCount: [],
      total_page: 0,
      page: 0,
      total_admin: 0,
      notifyUpdate: false,
      textNotify: '',
      timeoutNotify: 6000,
      colorNotify: '',
      transition: 'scale-transition',
      listLoading: true,
      titleNotify: ''
    }
  },

  computed: {
    lang () {
      return this.$vuetify.lang
    },
    columns () {
      let columns = []
      let mid = Math.ceil(this.dataActivity.length / 2)
      for (let col = 0; col < 2; col++) {
        columns.push(this.dataActivity.slice(col * mid, col * mid + mid))
      }
      return columns
    },
    columnsAdmin () {
      let columns = []
      let mid = Math.ceil(this.adminCount.length / 3)
      console.log('mid', mid)
      for (let col = 0; col < 3; col++) {
        columns.push(this.adminCount.slice(col * mid, col * mid + mid))
      }
      return columns
    }
  },

  mounted () {
    // this.$vuetify.lang.current = 'id'
    console.log('lang', this.$vuetify.lang.current)
    this.getAllAdmin(1)
    this.getCountry()
    this.getCity()
    this.getRole()
    this.getCurrentActivity()
    this.getAdminCount()
  },

  methods: {
    getAllAdmin (page) {
      this.listLoading = true
      var param = {
        page: page,
        per_page: 10
      }
      listAllAdmin(param).then((response) => {
        this.listAdmin = response.data.data
        this.total_admin = response.data.meta.total
        this.total_page = response.data.meta.last_page
        this.page = response.data.meta.current_page
        this.listLoading = false
      }).catch((error) => {
        console.log('rsp', error)
        this.listLoading = false
      })
      // this.listLoading = false
    },

    getCurrentActivity () {
      // this.listLoading = true
      var param = {
        sort_type: 'desc',
        sort_column: 'id'
      }
      currentActivity(param).then((response) => {
        this.dataActivity = response.data.data
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getAdminCount () {
      // this.listLoading = true
      var param = {
        per_page: 20
      }
      countAdmin(param).then((response) => {
        this.adminCount = response.data.data
        this.adminCount.map(i => {
          if (i.id === 'A') {
            i.color = '#FFEBEE'
            i.text_color = 'color: #C44569;'
          }
          if (i.id === 'S') {
            i.color = '#FFFDE7'
            i.text_color = 'color: #FFD32A;'
          }
          if (i.id === 'M') {
            i.color = '#E1F5FE'
            i.text_color = 'color: #3DC1D3;'
          }
          if (i.id === 'F') {
            i.color = '#F3E5F5'
            i.text_color = 'color: #786FA6'
          }
          if (i.id === 'DM') {
            i.color = '#E8F5E9'
            i.text_color = 'color: #05C46B'
          }
          if (i.id === 'TS') {
            i.color = '#E0F2F1'
            i.text_color = 'color: #55E6C1'
          }
          if (i.id === 'NA') {
            i.color = '#FCE4EC'
            i.text_color = 'color: #786FA6'
          }
        })
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getCountry () {
      // this.listLoading = true
      var param = {
        per_page: 300
      }
      listCountry(param).then((response) => {
        this.listCountry = response.data.data
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getCity () {
      // this.listLoading = true
      var param = {
        per_page: 300
      }
      listCity(param).then((response) => {
        this.listCity = response.data.data
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    getRole () {
      // this.listLoading = true
      var param = {
        per_page: 300
      }
      listAdminRole(param).then((response) => {
        this.listAdminRole = response.data.data
      }).catch((error) => {
        console.log('rsp', error)
      })
      // this.listLoading = false
    },

    saveAdmin () {
      // this.listLoading = true
      createAdmin(this.data).then((response) => {
        this.data = {}
        this.dialog = false
        this.getAllAdmin(1)
        this.getCurrentActivity()
        this.getAdminCount()
        console.log('rsp1', response)
      }).catch((error) => {
        this.titleNotify = error.statusCode
        this.textNotify = error.message
        this.notifyUpdate = true
        this.colorNotify = 'error'
        console.log('rsp', error)
      })
      // this.listLoading = false
    },
    rowClick (row) {
      this.$router.push('/admin/' + row.id)
      // console.log('row', row)
    },

    handleUpload (file) {
      console.log('upload', file)
    },

    addNewAdmin () {
      this.dialog = true
    },

    deleteItem (item) {
      confirm(this.lang.t('$vuetify.are_you_sure_delete') + item.name + ' ?') && this.delDataAdmin(item.id)
    },

    delDataAdmin (id) {
      // this.listLoading = true
      deleteAdmin(id).then((response) => {
        this.textNotify = response.data.data.message
        this.titleNotify = response.data.status
        this.notifyUpdate = true
        this.colorNotify = 'success'
        this.getAllAdmin(1)
        this.getCurrentActivity()
        this.getAdminCount()
      }).catch((error) => {
        console.log('rsp', error)
        this.textNotify = error
        this.notifyUpdate = true
        this.colorNotify = 'error'
      })
      // this.listLoading = false
    },

    getPhone (value) {
      this.data.mobile = value.formattedNumber
    },

    changePage (item) {
      this.getAllAdmin(item)
    }
  }
}
</script>
<style lang="scss">
.country-selector__toggle__arrow {
  display: none;
}
.country-selector {
  .country-selector__input {
    padding-top: 0px !important;
    width: 90px !important;
  }
  .country-selector__country-flag {
    top: 15px !important;
  }
  .input-tel__input {
    right: 30px !important;
    width: 195px !important;
    padding-top: 0px !important;
  }
  .field-input {
    height: 32px !important;
    min-height: 32px !important;
    padding-right: 0 !important;
  }

  .field-country-flag {
    top: 0px !important;
  }

  &.has-value {
    .field-input {
      padding-top: 0 !important;
    }
  }

  .country-selector-arrow {
    display: none;
  }
}
.signup-mode {
  .container-login-page {
    max-width: 1020px;
  }

  .login-form {
    overflow: auto;
  }

  .field.vue-input-ui.has-value .field-input {
    position: relative;
  }

  .field.vue-input-ui .field-input {
    height: 32px !important;
    min-height: 32px !important;
    position: relative !important;
    padding-top: 0 !important;
    top: 2px !important;
    right: -2px !important;
    border-left: none;
  }

  .country-selector {
    .field-input {
      height: 32px !important;
      min-height: 32px !important;
      padding-right: 0 !important;
    }

    .field-country-flag {
      top: 15px !important;
    }

    &.has-value {
      .field-input {
        padding-top: 0 !important;
      }
    }

    .country-selector-arrow {
      display: none;
    }
  }

  .vue-phone-number-input {
    .select-country-container {
      min-width: auto;
      max-width: auto;
      flex: 0 0 80px;
    }
  }
}
</style>
